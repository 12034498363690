import { auth, db, signOut } from "./firebase.js";
import {
  page,
  formatDate,
  showCustomLoadingOverlay,
  hideCustomLoadingOverlay,
  showCustomLoadingOverlayMain,
  hideCustomLoadingOverlayMain,
} from "./utilities.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
  OAuthProvider,
} from "firebase/auth";
import {
  doc,
  collection,
  setDoc,
  serverTimestamp,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { generateUsername } from "friendly-username-generator";
const options = {
  useHyphen: false, // You can change this to false to exclude hyphens
  useRandomNumber: true, // You can change this to false to exclude random numbers
};

var registerOptions = document.querySelectorAll(".registerOption");
var eventOption = document.querySelector(".eventOption");
var artistSubmitValue = localStorage.getItem("artistSubmitPageFlow");
var shopSubmitValue = localStorage.getItem("shopSubmitPageFlow");
var eventSubmitValue = localStorage.getItem("eventSubmitPageFlow");
const googleRegisterButton = document.getElementById("googleRegisterButton");
const appleRegisterButton = document.getElementById("appleRegisterButton");
const emailRegisterButton = document.getElementById("emailRegisterButton");
const emailRegistrationForm = document.getElementById("emailRegistrationForm");
const alreadyExist = document.getElementById("alreadyExist");

const googleLoginButton = document.getElementById("googleLoginButton");
const appleLoginButton = document.getElementById("appleLoginButton");
const emailLoginButton = document.getElementById("emailLoginButton");
const login_form = document.getElementById("login_form");
const alreadyDoesntExist = document.getElementById("alreadyDoesntExist");

/**********REGISTER PAGE***********/
if (page === "/register/") {
  document.addEventListener("DOMContentLoaded", async function () {
    showCustomLoadingOverlayMain();

    await Promise.all([checkAuthState(), loadPageResources()]);

    hideCustomLoadingOverlayWithDelay(); // Hide the overlay after everything is done

    const urlParams = new URLSearchParams(window.location.search);
    const option = urlParams.get("option");

    // Check if the parameter is present and select the appropriate radio field
    if (option) {
      if (option == "artist") {
        document.getElementById("flexRadioDefault2").checked = true; // Assuming the ID of the bronze radio field is 'bronzeRadio'
        localStorage.setItem("artistSubmitPageFlow", true);
      } else if (option == "bronze" || option == "silver" || option == "gold") {
        document.getElementById("flexRadioDefault3").checked = true;
        localStorage.setItem("shopSubmitPageFlow", true);
      } else if (option == "event") {
        registerOptions.forEach(function (option) {
          option.classList.add("d-none");
        });
        eventOption.classList.remove("d-none");
        document.getElementById("flexRadioDefault4").checked = true;
        localStorage.setItem("eventSubmitPageFlow", true);
      }
    }

    // Function to handle Google or Apple registration
    async function handleGoogleAppleRegistration(user) {
      showCustomLoadingOverlayMain();
      try {
        const accountsRef = collection(db, "accounts");
        const userDocRef = doc(accountsRef, user.uid);

        // Check if user already exists in Firestore
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          alreadyExist.textContent =
            "Account already exists. Please login instead.";
          alreadyExist.classList.remove("d-none");

          // signOut(auth)
          //   .then(() => {
          //     // Sign-out successful.
          //     setTimeout(function () {
          //       alreadyExist.classList.add("d-none");
          //     }, 3000);
          //     destroySession();
          //   })
          //   .catch((error) => {
          //     //console.log("logout error");
          //   });
        } else {
          const display_name = user.displayName || generateUsername(options); // Use Google displayName or generate one

          // Prepare user data based on whether it's an event, artist, or shop
          let userType = "lover"; // Default user type
          let account_sub_type = ""; // Additional sub-type if relevant
          if (localStorage.getItem("artistSubmitPageFlow") === "true") {
            userType = "artist";
          } else if (localStorage.getItem("shopSubmitPageFlow") === "true") {
            userType = "owner";
          } else if (localStorage.getItem("eventSubmitPageFlow") === "true") {
            account_sub_type = "event";
          }

          // Create user document in Firestore
          await setDoc(userDocRef, {
            active: true,
            email: user.email,
            display_name: display_name,
            uid: user.uid,
            provider: user.providerId,
            created: serverTimestamp(),
            account_type: userType,
            status: "created",
            account_sub_type: account_sub_type,
            email_subscriber: false, // Set to false, or handle subscription separately if needed
          });

          // Call function to register PHP session
          registerMemberProfile(user.uid);
        }
      } catch (error) {
        console.error("Error handling user registration:", error);
      } finally {
        hideCustomLoadingOverlayWithDelay(); // Ensure overlay hides in all cases
      }
    }

    function checkAuthState() {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          const isSocialLogin =
            localStorage.getItem("isSocialLogin") === "true"; // Check flag
          if (user && isSocialLogin) {
            await handleGoogleAppleRegistration(user); // Process user registration
            localStorage.removeItem("isSocialLogin");
          }
          resolve(); // Resolve the promise after processing the auth state
        });
      });
    }

    function loadPageResources() {
      return new Promise((resolve) => {
        setTimeout(() => {
          //console.log("Page resources loaded");
          resolve(); // Simulate resources being loaded
        }, 1000); // Simulate a 1-second loading time
      });
    }

    function hideCustomLoadingOverlayWithDelay() {
      setTimeout(() => {
        hideCustomLoadingOverlay();
      }, 500); // Adjust the delay as needed
    }


    function isTermsAgreed() {
      const agreeTermsCheckbox = document.getElementById("agreeTerms");
      if (!agreeTermsCheckbox.checked) {
        var termsModal = new bootstrap.Modal(
          document.getElementById("termsModal")
        );
        termsModal.show();
        termsError.textContent = "Please confirm below to proceed.";
        termsError.classList.remove("d-none");
        return false; // Validation failed
      }else{
        termsError.classList.add("d-none");
      }
      return true; // Validation passed
    }

    // Handle Email button
    emailRegisterButton.addEventListener("click", () => {
      const userTypeRadios = document.getElementsByName("flexRadioDefault");
      const subscribeCheckbox = document.getElementById("subscribe").checked;
      let userType = "";

      // Validate that a radio button is selected
      let isRadioButtonSelected = false;
      for (const radio of userTypeRadios) {
        if (radio.checked) {
          userType = radio.value; // Get the value of the checked radio button
          isRadioButtonSelected = true;
          break; // Exit loop once found
        }
      }

      // If no radio button is selected, show an error message and exit the function
      if (!isRadioButtonSelected) {
        radioError.textContent = "Please select a user type.";
        radioError.classList.remove("d-none");
        return;
      } else {
        radioError.classList.add("d-none");
      }

      // Store the selected user type in localStorage
      switch (userType) {
        case "artist":
          localStorage.setItem("artistSubmitPageFlow", true);
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "owner":
          localStorage.setItem("shopSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "event":
          localStorage.setItem("eventSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          break;
        default:
          localStorage.setItem("loverSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
      }
      emailRegistrationForm.style.display = "block";
      emailRegistrationForm.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "start", // Aligns the form at the top of the viewport
      });
    });

    // Handle Google Sign-in via button
    googleRegisterButton.addEventListener("click", () => {

      emailRegistrationForm.style.display = "none";
      localStorage.setItem("isSocialLogin", "true");
      const userTypeRadios = document.getElementsByName("flexRadioDefault");
      const subscribeCheckbox = document.getElementById("subscribe").checked;
      let userType = "";

      // Validate that a radio button is selected
      let isRadioButtonSelected = false;
      for (const radio of userTypeRadios) {
        if (radio.checked) {
          userType = radio.value; // Get the value of the checked radio button
          isRadioButtonSelected = true;
          break; // Exit loop once found
        }
      }

      // If no radio button is selected, show an error message and exit the function
      if (!isRadioButtonSelected) {
        radioError.textContent = "Please select a user type.";
        radioError.classList.remove("d-none");
        return;
      } else {
        radioError.classList.add("d-none");
      }

      // Store the selected user type in localStorage
      switch (userType) {
        case "artist":
          localStorage.setItem("artistSubmitPageFlow", true);
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "owner":
          localStorage.setItem("shopSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "event":
          localStorage.setItem("eventSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          break;
        default:
          localStorage.setItem("loverSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
      }
      if (!isTermsAgreed()) return;
      // Trigger the Google sign-in redirect
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    });

    // Handle Apple Sign-in via button
    appleRegisterButton.addEventListener("click", () => {

      emailRegistrationForm.style.display = "none";
      localStorage.setItem("isSocialLogin", "true");
      const userTypeRadios = document.getElementsByName("flexRadioDefault");
      const subscribeCheckbox = document.getElementById("subscribe").checked;
      let userType = "";

      // Validate that a radio button is selected
      let isRadioButtonSelected = false;
      for (const radio of userTypeRadios) {
        if (radio.checked) {
          userType = radio.value; // Get the value of the checked radio button
          isRadioButtonSelected = true;
          break; // Exit loop once found
        }
      }

      // If no radio button is selected, show an error message and exit the function
      if (!isRadioButtonSelected) {
        radioError.textContent = "Please select a user type.";
        radioError.classList.remove("d-none");
        return;
      } else {
        radioError.classList.add("d-none");
      }

      // Store the selected user type in localStorage
      switch (userType) {
        case "artist":
          localStorage.setItem("artistSubmitPageFlow", true);
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "owner":
          localStorage.setItem("shopSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
        case "event":
          localStorage.setItem("eventSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          break;
        default:
          localStorage.setItem("loverSubmitPageFlow", true);
          localStorage.removeItem("artistSubmitPageFlow");
          localStorage.removeItem("shopSubmitPageFlow");
          localStorage.removeItem("eventSubmitPageFlow");
          break;
      }
      if (!isTermsAgreed()) return;
      // Trigger the Apple sign-in redirect
      // Initialize the Apple provider
      const appleProvider = new OAuthProvider("apple.com");

      signInWithRedirect(auth, appleProvider);
    });

    const form = document.querySelector(".form-validate");

    form.addEventListener("submit", async (e) => {
      e.preventDefault();
      e.stopPropagation();
      // Check if the "Agree to Terms" checkbox is checked
      if (!isTermsAgreed()) return;

      showCustomLoadingOverlay();
      const email = document.getElementById("loginUsername").value;
      const password = document.getElementById("loginPassword").value;
      const confirmPassword = document.getElementById("loginPassword2").value;
      const display_name = generateUsername(options);
      const userTypeRadios = document.getElementsByName("flexRadioDefault");
      const subscribeCheckbox = document.getElementById("subscribe").checked;
      let userType = "";

      // Validate that a radio button is selected
      let isRadioButtonSelected = false;
      for (const radio of userTypeRadios) {
        if (radio.checked) {
          userType = radio.value; // Get the value of the checked radio button
          isRadioButtonSelected = true;
          break; // Exit loop once found
        }
      }

      // If no radio button is selected, show an error message and exit the function
      if (!isRadioButtonSelected) {
        hideCustomLoadingOverlay();
        radioError.textContent = "Please select a user type.";
        radioError.classList.remove("d-none");
        return;
      } else {
        radioError.classList.add("d-none");
      }

      // Validate that both password and confirm password match
      if (password !== confirmPassword) {
        hideCustomLoadingOverlay();
        passError.textContent = "Passwords do not match.";
        passError.classList.remove("d-none");
        return;
      } else {
        passError.classList.add("d-none");
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Access the user object from the userCredential
        const user = userCredential.user;

        // Check if the "accounts" collection exists, create it if it doesn't
        const accountsRef = collection(db, "accounts");

        // Create a new document in the "accounts" collection with the user's UID
        const userDocRef = doc(accountsRef, user.uid);

        if (userType === "event") {
          const userData = {
            active: true,
            email: user.email,
            display_name: display_name,
            uid: user.uid,
            provider: user.providerId,
            created: serverTimestamp(),
            account_type: "lover",
            status: "created",
            account_sub_type: "event",
            email_subscriber: subscribeCheckbox,
          };
          await setDoc(userDocRef, userData);
        } else {
          await setDoc(userDocRef, {
            active: true,
            email: user.email,
            display_name: display_name,
            uid: user.uid,
            provider: user.providerId,
            created: serverTimestamp(),
            account_type: userType,
            status: "created",
            email_subscriber: subscribeCheckbox,
          });
        }

        // Add user to newsletter collection if subscribed
        if (subscribeCheckbox) {
          const newsletterRef = collection(db, "newsletter");
          await addDoc(newsletterRef, {
            email: user.email,
            uid: user.uid,
            account_type: userType,
            subscribedAt: serverTimestamp(),
          });
        }

        registerMemberProfile(user.uid);
      } catch (error) {
        hideCustomLoadingOverlay();
        if (error.code === "auth/email-already-in-use") {
          loginError.textContent =
            "Account already exists. Please login instead.";
        } else if (error.code === "auth/weak-password") {
          loginError.textContent =
            "Password is invalid. It must be a string with at least six characters.";
        } else {
          loginError.textContent = "An error occurred. Please try again later.";
        }
        loginError.classList.remove("d-none"); // Show the error message
        console.error(error);
      }
    });

    function registerMemberProfile(uid) {
      getDoc(doc(db, "accounts", uid)).then((docSnap) => {
        if (docSnap.exists()) {
          const docdata = docSnap.data();
          const active = docdata.active;
          const user_name = docdata.display_name;
          const user_email = docdata.email;
          const provider = docdata.provider;
          const status = docdata.status;
          const created = formatDate(docdata.created.toMillis());
          const account_type = docdata.account_type;
          let account_sub_type = docdata.account_sub_type; // Declare account_sub_type variable

          // Check if account_sub_type is undefined
          if (typeof account_sub_type === "undefined") {
            account_sub_type = "";
          }
          setTimeout(function () {
            // CREATE PHP SESSION
            registerLoginSession(
              uid,
              active,
              user_name,
              user_email,
              provider,
              status,
              created,
              account_type,
              account_sub_type
            );
          }, 1000);
        } else {
          // USER IS NOT A MEMBER
          // noAccountFound();
        }
      });
    }

    // Register PHP Session
    function registerLoginSession(
      uid,
      active,
      user_name,
      user_email,
      provider,
      status,
      created,
      account_type,
      account_sub_type
    ) {
      $.ajax({
        method: "get",
        url: "/utilities/userAccounts.php",
        data: {
          uid: uid,
          active: active,
          user_name: user_name,
          user_email: user_email,
          provider: provider,
          status: status,
          created: created,
          account_type: account_type,
          account_sub_type: account_sub_type,
        },
        success: function (data) {
          $("#data").text(data);
          if (account_sub_type && account_sub_type == "event") {
            if (localStorage.getItem("eventSubmitPageFlow") === "true") {
              // Remove the value from local storage
              localStorage.removeItem("eventSubmitPageFlow");
              location = "/conventions/registration/";
            } else {
              location = "/conventions/start/";
            }
          } else {
            // Use a switch statement to perform different actions based on the value of userType
            switch (account_type) {
              case "lover":
                location = "/members/profile/";
                break;
              case "artist":
                if (artistSubmitValue) {
                  localStorage.removeItem("artistSubmitPageFlow");
                  location = "/members/artists/registration/";
                } else {
                  location = "/members/artists/registration/";
                }
                break;
              case "owner":
                if (shopSubmitValue) {
                  localStorage.removeItem("shopSubmitPageFlow");
                  location = "/members/owners/registration/";
                } else {
                  location = "/members/owners/start/";
                }
                break;
              default:
                console.log("User type is unknown.");
                break;
            }
          }
        },
        error: function (xhr, textStatus, error) {
          console.log(xhr.statusText);
          console.log(textStatus);
          console.log(error);
          console.log("PHP Session error");
        },
      });
    }
  });
}
/**********REGISTER PAGE END***********/
/**********REGISTER BACKUP PAGE***********/
// if (page === "/register/") {
//   document.addEventListener("DOMContentLoaded", function () {
//     // Get the parameter value from the URL
//     const urlParams = new URLSearchParams(window.location.search);
//     const option = urlParams.get("option");

//     // Check if the parameter is present and select the appropriate radio field
//     if (option) {
//       if (option == "artist") {
//         document.getElementById("flexRadioDefault2").checked = true; // Assuming the ID of the bronze radio field is 'bronzeRadio'
//         localStorage.setItem("artistSubmitPageFlow", true);
//       } else if (option == "bronze" || option == "silver" || option == "gold") {
//         document.getElementById("flexRadioDefault3").checked = true;
//         localStorage.setItem("shopSubmitPageFlow", true);
//       } else if (option == "event") {
//         registerOptions.forEach(function (option) {
//           option.classList.add("d-none");
//         });
//         eventOption.classList.remove("d-none");
//         document.getElementById("flexRadioDefault4").checked = true;
//         localStorage.setItem("eventSubmitPageFlow", true);
//       }
//     }
//   });

//   const form = document.querySelector(".form-validate");

//   form.addEventListener("submit", async (e) => {
//     e.preventDefault();

//     showCustomLoadingOverlay();

//     const email = document.getElementById("loginUsername").value;
//     const password = document.getElementById("loginPassword").value;
//     const confirmPassword = document.getElementById("loginPassword2").value;
//     const display_name = generateUsername(options);
//     const userTypeRadios = document.getElementsByName("flexRadioDefault");
//     const subscribeCheckbox = document.getElementById("subscribe").checked;
//     let userType = "";

//     // Validate that a radio button is selected
//     let isRadioButtonSelected = false;
//     for (const radio of userTypeRadios) {
//       if (radio.checked) {
//         userType = radio.value; // Get the value of the checked radio button
//         isRadioButtonSelected = true;
//         break; // Exit loop once found
//       }
//     }

//     // If no radio button is selected, show an error message and exit the function
//     if (!isRadioButtonSelected) {
//       hideCustomLoadingOverlay();
//       radioError.textContent = "Please select a user type.";
//       radioError.classList.remove("d-none");
//       return;
//     } else {
//       radioError.classList.add("d-none");
//     }

//     // Validate that both password and confirm password match
//     if (password !== confirmPassword) {
//       hideCustomLoadingOverlay();
//       passError.textContent = "Passwords do not match.";
//       passError.classList.remove("d-none");
//       return;
//     } else {
//       passError.classList.add("d-none");
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );

//       // Access the user object from the userCredential
//       const user = userCredential.user;

//       // Check if the "accounts" collection exists, create it if it doesn't
//       const accountsRef = collection(db, "accounts");

//       // Create a new document in the "accounts" collection with the user's UID
//       const userDocRef = doc(accountsRef, user.uid);

//       if (userType === "event") {
//         const userData = {
//           active: true,
//           email: user.email,
//           display_name: display_name,
//           uid: user.uid,
//           provider: user.providerId,
//           created: serverTimestamp(),
//           account_type: "lover",
//           status: "created",
//           account_sub_type: "event",
//           email_subscriber: subscribeCheckbox,
//         };
//         await setDoc(userDocRef, userData);
//       } else {
//         await setDoc(userDocRef, {
//           active: true,
//           email: user.email,
//           display_name: display_name,
//           uid: user.uid,
//           provider: user.providerId,
//           created: serverTimestamp(),
//           account_type: userType,
//           status: "created",
//           email_subscriber: subscribeCheckbox,
//         });
//       }

//       // Add user to newsletter collection if subscribed
//       if (subscribeCheckbox) {
//         const newsletterRef = collection(db, "newsletter");
//         await addDoc(newsletterRef, {
//           email: user.email,
//           uid: user.uid,
//           account_type: userType,
//           subscribedAt: serverTimestamp(),
//         });
//       }

//       registerMemberProfile(user.uid);
//     } catch (error) {
//       hideCustomLoadingOverlay();
//       if (error.code === "auth/email-already-in-use") {
//         loginError.textContent =
//           "Account already exists. Please login instead.";
//       } else if (error.code === "auth/weak-password") {
//         loginError.textContent =
//           "Password is invalid. It must be a string with at least six characters.";
//       } else {
//         loginError.textContent = "An error occurred. Please try again later.";
//       }
//       loginError.classList.remove("d-none"); // Show the error message
//       console.error(error);
//     }
//   });

//   function registerMemberProfile(uid) {
//     getDoc(doc(db, "accounts", uid)).then((docSnap) => {
//       if (docSnap.exists()) {
//         const docdata = docSnap.data();
//         const active = docdata.active;
//         const user_name = docdata.display_name;
//         const user_email = docdata.email;
//         const provider = docdata.provider;
//         const status = docdata.status;
//         const created = formatDate(docdata.created.toMillis());
//         const account_type = docdata.account_type;
//         let account_sub_type = docdata.account_sub_type; // Declare account_sub_type variable

//         // Check if account_sub_type is undefined
//         if (typeof account_sub_type === "undefined") {
//           account_sub_type = "";
//         }
//         setTimeout(function () {
//           // CREATE PHP SESSION
//           registerLoginSession(
//             uid,
//             active,
//             user_name,
//             user_email,
//             provider,
//             status,
//             created,
//             account_type,
//             account_sub_type
//           );
//         }, 1000);
//       } else {
//         // USER IS NOT A MEMBER
//         // noAccountFound();
//       }
//     });
//   }

//   function registerLoginSession(
//     uid,
//     active,
//     user_name,
//     user_email,
//     provider,
//     status,
//     created,
//     account_type,
//     account_sub_type
//   ) {
//     $.ajax({
//       method: "get",
//       url: "/utilities/userAccounts.php",
//       data: {
//         uid: uid,
//         active: active,
//         user_name: user_name,
//         user_email: user_email,
//         provider: provider,
//         status: status,
//         created: created,
//         account_type: account_type,
//         account_sub_type: account_sub_type,
//       },
//       success: function (data) {
//         $("#data").text(data);
//         setTimeout(function () {
//           setTimeout(function () {
//             hideCustomLoadingOverlay();
//           }, 2000);
//           hideCustomLoadingOverlay();
//           if (account_sub_type && account_sub_type == "event") {
//             console.log("event");
//             if (localStorage.getItem("eventSubmitPageFlow") === "true") {
//               // Remove the value from local storage
//               localStorage.removeItem("eventSubmitPageFlow");
//               location = "/conventions/registration/";
//             } else {
//               location = "/conventions/start/";
//             }
//           } else {
//             // Use a switch statement to perform different actions based on the value of userType
//             switch (account_type) {
//               case "lover":
//                 console.log("User is a tattoo lover.");
//                 location = "/members/profile/";
//                 break;
//               case "artist":
//                 console.log("User is a tattoo artist.");

//                 if (artistSubmitValue) {
//                   // Remove the value from local storage
//                   localStorage.removeItem("artistSubmitPageFlow");
//                   location = "/members/artists/start/";
//                 } else {
//                   location = "/members/artists/start/";
//                 }

//                 break;
//               case "owner":
//                 console.log("User is a tattoo shop owner.");

//                 if (shopSubmitValue) {
//                   localStorage.removeItem("shopSubmitPageFlow");
//                   location = "/members/owners/registration/";
//                 } else {
//                   location = "/members/owners/start/";
//                 }

//                 break;
//               default:
//                 console.log("User type is unknown.");
//                 // Additional actions for unknown user types
//                 break;
//             }
//           }
//         }, 1000);
//       },
//       error: function (xhr, textStatus, error) {
//         console.log(xhr.statusText);
//         console.log(textStatus);
//         console.log(error);
//         console.log("PHP Session error");
//       },
//     });
//   }
// }
/**********REGISTER BACKUP PAGE***********/

/**********LOGIN PAGE***********/
if (page === "/login/") {
  document.addEventListener("DOMContentLoaded", async function () {
    showCustomLoadingOverlay();

    // Wait for authentication state change and profile fetch to complete
    await Promise.all([checkAuthState(), loadPageResources()]);

    hideCustomLoadingOverlayWithDelay(); // Hide the overlay after everything is done

    function hideCustomLoadingOverlayWithDelay() {
      setTimeout(() => {
        hideCustomLoadingOverlay();
      }, 500); // Adjust the delay as needed
    }

    // Helper function to manage authentication state changes
    async function checkAuthState() {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          const isSocialLogin =
            localStorage.getItem("isSocialLogin") === "true"; // Check flag
          if (user && isSocialLogin) {
            await memberLoginProfile(user.uid);
            localStorage.removeItem("isSocialLogin");
          }
          resolve(); // Resolve the promise after checking auth state
        });
      });
    }

    // Simulate other resources loading (e.g., assets, API calls)
    async function loadPageResources() {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log("Page resources loaded");
          resolve(); // Resolve the promise after resources are loaded
        }, 1000); // Simulate some loading time
      });
    }

    // // Listen for changes to the user's authentication state
    // onAuthStateChanged(auth, async (user) => {
    //   if (user) {
    //     showCustomLoadingOverlay(); // Optional, if you want to show a loader while fetching the profile

    //     // Fetch and handle the user's profile
    //     await memberLoginProfile(user.uid);

    //     hideCustomLoadingOverlay(); // Hide the loading overlay once done
    //   } else {
    //     hideCustomLoadingOverlay();
    //     // Optional: Logic for when no user is signed in (e.g., redirect to login page, or show a message)
    //   }
    // });

    // Handle Apple Sign-in via button
    appleLoginButton.addEventListener("click", async () => {
      login_form.style.display = "none";
      localStorage.setItem("isSocialLogin", "true");
      loginError.classList.add("d-none"); // Clear error message
      const appleProvider = new OAuthProvider("apple.com");
      try {
        await signInWithRedirect(auth, appleProvider);
      } catch (error) {
        loginError.textContent =
          "Failed to sign in with Apple. Please try again.";
        loginError.classList.remove("d-none");
      }
    });

    // Handle Google Sign-in via button
    googleLoginButton.addEventListener("click", async () => {
      login_form.style.display = "none";
      localStorage.setItem("isSocialLogin", "true");
      loginError.classList.add("d-none"); // Clear error message
      const provider = new GoogleAuthProvider();
      try {
        await signInWithRedirect(auth, provider);
      } catch (error) {
        loginError.textContent =
          "Failed to sign in with Google. Please try again.";
        loginError.classList.remove("d-none");
      }
    });
    emailLoginButton.addEventListener("click", () => {
      login_form.style.display = "block"; // Show email login form
      loginError.classList.add("d-none"); // Clear previous errors
    });

    //EMAIL LOGIN FORM
    login_form.addEventListener("submit", async (e) => {
      e.preventDefault();
      showCustomLoadingOverlay();
      const email = document.getElementById("loginUsername").value;
      const password = document.getElementById("loginPassword").value;

      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Access the user object from the userCredential
        const user = userCredential.user;

        // User signed up successfully
        //console.log("User loggedin:", user);

        memberLoginProfile(user.uid);
      } catch (error) {
        JsLoadingOverlay.hide();
        console.log(error.code);
        // Display appropriate error message
        if (error.code === "auth/user-not-found") {
          loginError.textContent = "User Not Found.";
        } else if (
          error.code === "auth/invalid-password" ||
          error.code === "auth/invalid-credential" ||
          error.code === "invalid-email"
        ) {
          loginError.textContent = "Invalid email or password.";
        } else {
          loginError.textContent = "An error occurred. Please try again later.";
        }
        loginError.classList.remove("d-none"); // Show the error message
      }
    });

    async function memberLoginProfile(uid) {
      showCustomLoadingOverlay();
      try {
        const docSnap = await getDoc(doc(db, "accounts", uid)); // Use await directly on getDoc

        if (docSnap.exists()) {
          const docdata = docSnap.data();
          const active = docdata.active;
          const user_name = docdata.display_name;
          const user_email = docdata.email;
          const provider = docdata.provider;
          const status = docdata.status;
          const created = formatDate(docdata.created.toMillis());
          const account_type = docdata.account_type;

          // CREATE PHP SESSION
          loginSession(
            uid,
            active,
            user_name,
            user_email,
            provider,
            status,
            created,
            account_type
          );
        } else {
          // USER IS NOT A MEMBER
          console.log("No user profile found. Deleting user...");
          const user = auth.currentUser;

          if (user) {
            await user.delete(); // Delete the Firebase auth user
            console.log("User deleted successfully.");

            // Optionally, you can also sign out the user to clean up the session
            await signOut(auth);
            console.log("User signed out after deletion.");
            alreadyDoesntExist.textContent =
              "No account found. Please try a different login or register instead.";
            alreadyDoesntExist.classList.remove("d-none");
            setTimeout(() => {
              alreadyDoesntExist.classList.add("d-none");
            }, 5000); // Wait 3 seconds before starting the fade-out
          }
        }
      } catch (error) {
        console.error("Error fetching user profile or deleting user:", error);
        // Handle errors, e.g., show an error message to the user
      } finally {
        hideCustomLoadingOverlayWithDelay(); // Ensure overlay hides in all cases
      }
    }

    function loginSession(
      uid,
      active,
      user_name,
      user_email,
      provider,
      status,
      created,
      account_type
    ) {
      $.ajax({
        method: "get",
        url: "/utilities/userAccounts.php",
        data: {
          uid: uid,
          active: active,
          user_name: user_name,
          user_email: user_email,
          provider: provider,
          status: status,
          created: created,
          account_type: account_type,
        },
        success: function (data) {
          $("#data").text(data);
          // Use a switch statement to perform different actions based on the value of userType
          switch (account_type) {
            case "lover":
              location = "/members/profile/";
              break;
            case "artist":
              location = "/members/artists/profile/";
              break;
            case "owner":
              location = "/members/owners/profile/";
              break;
            default:
              console.log("User type is unknown.");
              // Additional actions for unknown user types
              break;
          }
        },
        error: function (xhr, textStatus, error) {
          console.log(xhr.statusText);
          console.log(textStatus);
          console.log(error);
          console.log("PHP Session error");
        },
      });
    }
  });
}

/**********LOGIN PAGE***********/

/**********LOGOUT PAGE***********/
if (page === "/logout/") {
  import("./utilities.js").then((utilities) => {
    const { logoutAccount } = utilities;
    logoutAccount();
  });
}

/**********FORGOT PASSWORD PAGE***********/
if (page === "/forgot/") {
  const form = document.getElementById("forgot_form");

  form.addEventListener("submit", async (e) => {
    e.preventDefault();

    const email = document.getElementById("forgotPasswordEmail").value;

    try {
      // Send a password reset email to the user's email address
      await sendPasswordResetEmail(auth, email);

      var resetPasswordModal = new bootstrap.Modal(
        document.getElementById("resetPasswordModal")
      );
      resetPasswordModal.show();
    } catch (error) {
      // Handle password reset errors
      // console.error(error);
      // Display appropriate error message
      alert("An error occurred. Please try again later.");
    }
  });

  // Add an event listener to the modal close button
  document
    .getElementById("resetPasswordModal")
    .addEventListener("hidden.bs.modal", function () {
      // Redirect the user to the login page after closing the modal
      window.location.href = "/login/";
    });
}
/**********FORGOT PASSWORD PAGE***********/
